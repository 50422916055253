.boarding h5 {
  margin-bottom: 16px;
}

.boarding .boarding-details-title {
  margin-bottom: 8px;
}

.boarding .top-container {
  background-color: #F7F9FB;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 56px;
}

.boarding .header {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.boarding .header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-right: 80px;
  width: 50%;
}

.boarding .header-right {
  width: 50%;
}

.boarding .header-left .icon-with-title {
  margin-bottom: 8px;
}

.boarding .header .typeTitle {
  opacity: 0.4;
}

.boarding .header .title {
  margin-bottom: 48px;
}

.boarding .updates {
  margin-top: 56px;
}

.boarding .loading {
  margin-top: 200px;
  justify-content: center;
}

.boarding .activity-notes {
  display: flex;
  justify-content: left;
  width: 70%;
  margin-bottom: 56px;
  padding: 0px;
}

.boarding .header {
  width: 100%;
}

@media (max-width: 1024px) {
  .boarding .top-container {
    padding-top: 0px;
  }

  .boarding .top-container .wide-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .boarding .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .boarding .header-left {
    flex-direction: column;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }

  .boarding .header-right {
    width: 100%;
  }

  .boarding .activity-notes {
    width: 100%;
  }

}
  
@media (max-width: 700px) {
  .boarding .top-container {
    margin-bottom: 40px;
  }

  .boarding .map-container {
    max-height: 200px;
  }

  .boarding .header-left {
    padding-left: 16px;
    padding-right: 16px;
  }

  .boarding .header .title {
    margin-bottom: 32px;
  }

  .boarding .activity-notes {
    margin-bottom: 32px;
  }
}