.error-page .error-page-content {
  height: 80vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.error-page .error-page-content h5 {
  padding-top: 32px;
}

.error-page .error-page-content .error-page-illustration {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.inbox.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 300px;
  height: 300px;
}

.error-page footer {
  margin-top: auto;
}
