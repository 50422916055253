.qualifications {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.qualifications h6 {
    margin-bottom: 8px;
}

@media (max-width: 900px) {
    .qualifications {
        margin-top: 16px;
    }
}