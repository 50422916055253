.dogs-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.dogs-container .dog-item {
  width: calc(50% - 8px);
  background-color: #F7F9FB;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.dogs-container .dog {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.dogs-container .dog-top {
  padding: 16px;
  background-color: #ffffff;
}

.dogs-container .dog-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.dogs-container .dog-checklist {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 24px;
  flex-wrap: wrap;
}

.dogs-container .dog-notes {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: left;
}


.dogs-container .dog-notes .dog-notes-title {
  margin-bottom: 8px;
  opacity: 0.4;
}

.dogs-container .dog .dog-image {
  margin-right: 16px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 700px) {
  .dogs-container {
    margin-bottom: 40px;
  }

  .dogs-container .dog-item {
    width: 100%;
  }

  .dogs-container .dog-top {
    padding: 16px;
  }

  .dogs-container .dog-checklist {
    margin-top: 16px;
  }

  .dogs-container .dog-notes {
    padding: 16px;
  }
}