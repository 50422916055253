.services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.icon-with-title-and-description {
    padding: 16px !important;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.services .loader {
    margin-bottom: 24px;
}

.services h5 {
    margin-bottom: 16px;
}

@media (max-width: 1200px) {
    .services {
        margin-left: 0px;
        margin-right: 0px;
    }
}