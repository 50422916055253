
.tncs .jumbo {
  background: #F7F9FB;
  padding-top: 80px;
}

.tncs .jumbo h2 {
  width: 100%;
  color: #1AE0B3;
  padding-top: 80px;
  padding-bottom: 80px;
}

.tncs .wide-container h2 {
  margin-top: 80px;
  font-size: 22px;
  font-weight: 900;
  line-height: 26px;
  width: 70%;
}

.tncs .wide-container p {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 21px;
  width: 70%;
}

@media (max-width: 1024px) {
  .tncs .wide-container h2 {
    width: 100%;
  }

  .tncs .wide-container p {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .tncs .jumbo h2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tncs .wide-container h2 {
    margin-top: 40px;
  }
}
