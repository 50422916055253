.subscribe-from-link {
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.subscribe-from-link h5 {
	margin-top: 16px;
	text-align: center;
}