.customer-explanation .top-container {
    margin-top: 80px;
    background-color: #F7F9FB;
} 
  
.customer-explanation .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.customer-explanation .top-container .header .title {
    color: #1AE0B3;
}

.customer-explanation .top-container .header .intro {
    width: 70%;
}

.customer-explanation .content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.customer-explanation .content li {
    width: 60%;
    list-style-type: decimal;
}

.customer-explanation .content h3 {
    width: 60%;
}

.customer-explanation .content p {
    width: 60%;
    margin-bottom: 40px;
}

.customer-explanation .content .button-primary {
    width: 250px;
    margin-top: 16px;
    margin-bottom: 80px
}


.customer-explanation .showcase-left {
    background-color: #F7F9FB;
}

@media (max-width: 800px) {
    .customer-explanation .top-container .header .intro {
        width: 100%;
    }

    .customer-explanation .content h3 {
        width: 100%;
    }

    .customer-explanation .content li {
        width: 100%;
    }

    .customer-explanation .content p {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .customer-explanation .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .customer-explanation .content {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .customer-explanation .content .button-primary {
        margin-bottom: 40px
    }
}