.dropins .top-container {
    margin-top: 80px;
    background-color: #F7F9FB;
}
  
.dropins .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.dropins .top-container .header .title {
    color: #1AE0B3;
}

.dropins .top-container .header .intro {
    width: 70%;
}

@media (max-width: 800px) {
    .dropins .top-container .header .intro {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .dropins .content {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 56x;
    }

    .dropins .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}