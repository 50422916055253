.download .top-container {
    margin-top: 80px;
    background-color: #F7F9FB;
}
  
.download .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.download .top-container .header .title {
    color: #1AE0B3;
    width: 70%;
}

.download .download-content {
    display: flex;
    flex-direction: row;
}

.download .showcase-left {
    background-color: #F7F9FB;
}

@media (max-width: 700px) {
    .download .top-container .header .title {
        width: 100%;
    }

    .download .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}