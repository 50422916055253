.login .top-container {
    margin-top: 80px;
}
  
.login .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.login .top-container .header .title {
    color: #A940AA;
}

.login .top-container .header .intro {
    width: 70%;
}

.login .content {
    display: flex;
    flex-direction: row;
}

.login .content .button-facebook {
    margin-bottom: 16px;
}

.login .content .button-google {
    margin-bottom: 16px;
}

.login .content .button-facebook {
    margin-bottom: 16px;
}

.login .left-column {
    width: 60%;
    padding-right: 80px;
}

.login .right-column {
    width: 40%;
}

@media (max-width: 800px) {
    .login .top-container .header .intro {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .login .content {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 56x;
    }

    .login .content p {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .login .left-column {
        width: 100%;
        padding-right: 0px;
    }
    
    .login .right-column {
        width: 100%;
    }

    .login .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}