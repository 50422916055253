.icon-with-title-and-description {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-bottom: 32px;
}
  
  .icon-with-title-and-description .type-image {
      margin-right: 16px;
      height: 24px;
      width: 24px;
}

.icon-with-title-and-description .icon-with-title-and-description-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.icon-with-title-and-description .icon-with-title-and-description-content h5 {
    margin-bottom: 4px;
}