.showcase-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 16px;
}

.showcase-left h3 {
    margin-bottom: 8px;
}

.showcase-left .showcase-features {
    margin-top: 32px;
}

.showcase-left .showcase-left-column {
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.showcase-left .showcase-right-column {
    padding-left: 56px;
    padding-right: 56px;
    width: 60%;
}

.showcase-left .showcase-left-column .feature-image {
    width: 80%;
}

.showcase-left .showcase-features .icon-with-title {
    margin-bottom: 16px;
}

.showcase-left .showcase-left-badges {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 40px;
}

.showcase-left .showcase-left-badges .showcase-left-appstore {
    margin-right: 8px;
  }
  
.showcase-left .showcase-left-badges .showcase-left-playstore {
    margin-left: 8px;
}

@media (max-width: 1440px) {
  .showcase-left {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (max-width: 800px) {
    .showcase-left {
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .showcase-left .showcase-left-column {
        margin-bottom: 40px;
        align-items: flex-start;
        width: 100%;
    }

    .showcase-left .showcase-left-badges {
        flex-direction: column;
    }

    .showcase-left .showcase-left-badges .showcase-left-playstore {
        margin-left: 0px;
        margin-top: 8px;
    }

    .showcase-left .showcase-right-column {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }

    .showcase-left .showcase-left-column .feature-image {
        width: 50%;
    }
}

@media (max-width: 400px) {
    .showcase-left .showcase-left-column .feature-image {
        width: 80%;
    }
}