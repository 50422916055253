.boardingUpdates .update-container {
    display: flex;
    align-items: left;
    flex-direction: row;
    justify-content: left;
}

.boardingUpdates .status {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 8px;
    max-width: 24px;
}

.boardingUpdates .button a {
    margin: 16px;
    margin-top: 0px;
}

.boardingUpdates .type-image {
    width: 24px;
    height: 24px;
}

.boardingUpdates .type-video {
    width: 24px;
    height: 24px;
}

.boardingUpdates .line {
    background-color: #1AE0B3;
    padding: 0px;
    width: 2px;
    height: 100%;
}

.boardingUpdates .update {
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 100%;
}

.boardingUpdates .update-content {
    background-color: #F7F9FB;
    border-radius: 12px;
    overflow: hidden;
    width: 70%;
}

.boardingUpdates .notes {
    padding: 15px;
}

.boardingUpdates .time {
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 12px;
    opacity: 0.5;
    margin-right: auto;
    padding-bottom: 30px;
}

.boardingUpdates img {
    width: 100%;
    height: 100%;
}

.boardingUpdates .video {
    width: 100%;
}

.boardingUpdates .react-player > video {
    border-radius: 12px;
    overflow: hidden;
  }

.boardingUpdates .map-container {
    width: 100%;
    height: 45vh;
}

@media (max-width: 700px) {
    .boardingUpdates .update-content {
        width: 100%;
    }
}