.button-secondary {
    font-family: SFRounded;
    align-items: center;
    height: 60px;
    width: 100%;
    color: #0038FF;
    font-size: 20px;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    border-color: #0038FF;
    border-width: 1px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: #F7F9FB;
    transition: 0.3s;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
}

.button-secondary:hover {
    background: #B2D3FF;
}

@media (max-width: 700px) {
    .button-secondary {
        font-size: 18px;
        height: 55px;
    }
}