.pricing .top-container {
    margin-top: 80px;
    background-color: #F7F9FB;
    margin-bottom: 80px;
}
  
.pricing .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.pricing .top-container .header .title {
    color: #1AE0B3;
    width: 70%;
}

.pricing .pricing-tiers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 80px;
}

.pricing .contact-us {
    margin-top: 128px;
}

@media (max-width: 1024px) {
    .pricing .top-container .header .title {
        width: 100%;
    }

    .pricing .pricing-tiers {
        flex-direction: column;
        margin-top: 40px;
    }

    .pricing .contact-us {
        margin-top: 80px;
    }
}

@media (max-width: 700px) {
    .pricing .top-container {
        margin-bottom: 40px;
    }  

    .pricing .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}