.contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
}

.contact h6 {
    margin-bottom: 8px;
}

.contact a {
    text-decoration-color: #2D2C5D;
}

.contact .icon-with-title h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
}

@media (max-width: 900px) {
    .contact {
        margin-top: 16px;
        margin-bottom: 24px;
    }
}