.contact-us {
    margin-top: 104px;
    margin-bottom: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-us .contact-us-left-column {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-right: 80px;
    width: 60%;
}

.contact-us .contact-us-right-column {
    width: 40%;
}

@media (max-width: 800px) {
    .contact-us {
        flex-direction: column;
    }

    .contact-us .contact-us-left-column {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 40px;
    }

    .contact-us .contact-us-right-column {
        width: 100%;
    }
}