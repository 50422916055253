.react-photo-gallery--gallery > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.react-photo-gallery--gallery .photo {
  margin-bottom: 16px;
  margin-right: 8px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.loading {
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
}

.image_1swebtw-o_O-imageLoaded_zgbg08 {
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .react-photo-gallery--gallery .photo {
    max-width: calc(50% - 8px);
  }
}

@media (max-width: 600px) {
  .react-photo-gallery--gallery .photo {
    margin-bottom: 8px;
  }
}