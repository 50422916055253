.footer-powered-by-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
}

.footer-powered-by-content .badges {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.footer-powered-by .columns {
    display: flex;
    justify-content: space-between;
    flex: row;
}

.footer-powered-by .left-columns {
    display: flex;
    align-items: center;
    flex: row;
}

.footer-powered-by .help-column {
    margin-right: 24px;
}

.footer-powered-by .about-column {
    margin-right: 24px;
}

.footer-powered-by {
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-powered-by-content .button-secondary {
    width: 100px;
}

.footer-powered-by-content a {
    margin-top: 16px;
    margin-bottom: 16px;
    color: #2D2C5D !important;
    text-decoration: none;
}

.footer-powered-by-content a:hover {
    color: #1AE0B3 !important;
}
  
@media (max-width: 800px) {
    .footer-powered-by .columns {
        flex-direction: column;
        align-items: center;
    }

    .footer-powered-by .left-columns {
        margin-bottom: 56px;
    }
}
  
@media (max-width: 680px) {
    .footer-powered-by-content a {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .footer-powered-by-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .footer-powered-by-content .badges {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}