.latest-activities {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
}

.latest-activities h5 {
    margin-bottom: 16px;
}

.latest-activities .activities {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

@media (max-width: 500px) {
    .latest-activities {
        margin-top: 40px;
    }

    .latest-activities .activities {
        flex-direction: column;
    }
}