.invoice .content-container {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
}

.invoice .content-container .left-column {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding-right: 80px;
    width: 50%;
    margin-top: 200px;
}

.invoice .content-container .left-column .payment-method {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
}

.invoice .content-container .left-column .payment-method h4 {
    margin-top: 16px;
}

.invoice .content-container .left-column .payment-method h5 {
    margin-top: 16px;
}

.invoice .content-container .left-column .payment-method .button-primary {
    margin-top: 32px;
    width: 200px;
}

.invoice .content-container .right-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #F5F6F6;
    padding: 32px;
    width: 50%;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 8px;
}

.invoice .content-container .right-column .invoice-services {
    margin-bottom: 16px;
    width: 100%;
}

.invoice .content-container .right-column h5 {
    margin-bottom: 4px;
}

.invoice .content-container .right-column .invoice-services .icon-with-title-and-accessory {
    margin-bottom: 8px;
}

.invoice .content-container .right-column .invoice-services h5 {
    margin-bottom: 24px;
}

.invoice .content-container .right-column .invoice-services .invoice-services-total {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    width: 100%;
}

.invoice .content-container .right-column .invoice-services .invoice-services-total h6 {
    margin-right: 56px
}

.invoice .content-container .right-column .invoice-services .invoice-services-total p {
    margin-right: 0px;
    margin-left: auto;
}

.invoice .content-container .right-column .invoice-due-date {
    margin-top: 32px;
    width: 100%;
}

.invoice .content-container .right-column .invoice-dates-covered {
    margin-top: 32px;
    width: 100%;
}

.invoice .content-container .right-column .invoice-notes {
    margin-top: 32px;
    width: 100%;
}

.invoice .content-container .right-column .invoice-meta {
    margin-top: 32px;
    width: 100%;
}

.invoice .content-container .left-column .small-print {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
}

.invoice .loading {
    margin-top: 200px;
    justify-content: center;
}

.invoice .paying {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}

.invoice .loader {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
}

.invoice .company-logo {
    margin: auto;
    max-height: 150px;
}

.card-field {
    margin-top: 40px;
    box-sizing: border-box;
    align-items: center;
    height: 60px;
    width: auto;
    padding: 22px 10px;
    border-radius: 16px;
    background-color: #F5F6F6;
}

.request-button {
    margin-top: 16px;
}

.payment-badges {
    margin-top: 32px;
}

.invoice .content-container .left-column .failure-message {
    margin-top: 24px;
    margin-bottom: 32px;
    color: #F44336;
    font-size: 12px;
    line-height: 16px;
}

.invoice .content-container .left-column .invoice-pay-link .tip-form-title {
    margin-bottom: 4px;
}

.invoice .content-container .left-column .invoice-pay-link .tip-form-description {
    margin-bottom: 16px;
}

.invoice .content-container .left-column .invoice-pay-link .tip-form .invoice-form-text-field {
    font-family: SFRounded;
    color: #2F2D5E;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    height: 60px;
    width: 100%;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    box-sizing: border-box;
    align-items: center;
    height: 60px;
    padding: 22px 10px;
    border-radius: 16px 16px 0px 0px;
    -moz-border-radius: 16px 16px 0px 0px;
    -webkit-border-radius: 16px 16px 0px 0px;
    text-decoration: none;
    border: 2px solid #EDEEF1;
    border-bottom: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 16px;
    padding-left: 16px;
}

.invoice .content-container .left-column .invoice-pay-link .tip-form .invoice-form-button {
    font-family: SFRounded;
    color: #2F2D5E;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    align-items: center;
    height: 60px;
    width: 100%;
    color: white;
    font-size: 20px;
    border-radius: 0px 0px 16px 16px;
    -moz-border-radius: 0px 0px 16px 16px;
    -webkit-border-radius: 0px 0px 16px 16px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: #0038FF;
    transition: 0.3s;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 16px;
}

*:focus {
    outline: none;
}


.invoice .content-container .left-column .invoice-pay-link .tip-form .invoice-form-button:hover {
    background: #025FDF;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.invoice .pay-deposit-button {
    margin-top: 16px;
}

@media (max-width: 1200px) {
    .invoice .content-container .left-column {
        padding-right: 40px !important;
    }
}

@media (max-width: 800px) {
    .invoice .content-container {
        flex-direction: column;
    }

    .invoice .paying {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .invoice .content-container .left-column {
        padding-right: 0px;
        margin-top: 80px;
        padding-right: 0px !important;
        width: 100%;
    }

    .invoice .content-container .right-column {
        width: 100%;
        padding: 24px;
    }

    .invoice .content-container .left-column .payment-method {
        padding-left: 104px;
        padding-right: 104px;
    }

    .invoice .content-container .right-column .invoice-meta {
        margin-top: 24px;
    }

    .invoice .content-container .right-column .invoice-services .icon-with-title-and-accessory {
        margin-bottom: 16px;
    }
}

@media (max-width: 700px) {
    .invoice .content-container .left-column .invoice-pay-link .tip-form .invoice-form-button {
        font-size: 18px;
        height: 55px;
    }
}

@media (max-width: 600px) {
    .invoice .content-container .left-column .payment-method {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 400px) {
    .invoice .content-container .left-column .payment-method {
        padding-left: 0px;
        padding-right: 0px;
    }
}