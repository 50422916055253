.showcase-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 16px;
}

.showcase-right h3 {
    margin-bottom: 8px;
}

.showcase-right .showcase-features {
    margin-top: 32px;
}

.showcase-right .showcase-left-column {
    display: flex;
	flex-direction: column;
    justify-content: center;
    padding-left: 56px;
    padding-right: 56px;
    width: 60%;
}

.showcase-right .showcase-right-column {
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.showcase-right .showcase-right-column .feature-image {
    width: 80%;
}

.showcase-right .showcase-features .icon-with-title {
    margin-bottom: 16px;
}

.showcase-right .showcase-right-badges {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 40px;
}

.showcase-right .showcase-right-badges .showcase-right-appstore {
    margin-right: 8px;
  }
  
.showcase-right .showcase-right-badges .showcase-right-playstore {
    margin-left: 8px;
}

@media (max-width: 1440px) {
  .showcase-right {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (max-width: 800px) {
    .showcase-right {
        flex-direction: column-reverse;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .showcase-right .showcase-left-column {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }

    .showcase-right .showcase-right-badges {
        flex-direction: column;
    }

    .showcase-right .showcase-right-badges .showcase-right-playstore {
        margin-left: 0px;
        margin-top: 8px;
    }

    .showcase-right .showcase-right-column {
        width: 100%;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    .showcase-right .showcase-right-column .feature-image {
        width: 50%;
    }
}

@media (max-width: 400px) {
    .showcase-right .showcase-right-column .feature-image {
        width: 80%;
    }
}