.side-menu .menu {
    background-color: white;
}
  
.side-menu .bm-burger-button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 16px;
    top: 24px;
}

.side-menu .bm-cross-button {
    margin-right: 16px;
    top: 30.5px !important;
    height: 32px !important;
    width: 32px !important;
}

.side-menu .bm-item:focus {
    outline: none;
}

.side-menu .bm-item {
    padding: 16px;
}

.side-menu .bm-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.side-menu .menu-item-top {
    margin-top: 40px;
}

.side-menu .bm-item-list {
    padding-top: 40px;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 128px;
  }

.side-menu .button-primary {
    margin-top: 32px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    width: calc(100% - 16px);
    height: 60px;
}

.side-menu a {
    color: #0038FF !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

.side-menu .bm-overlay {
    background: rgba(61, 207, 174, 0.8) !important;
}

@media (max-height: 700px) {
    .side-menu .menu-item-top {
        margin-top: 24px;
    }
}

@media (max-width: 320px) {
    .side-menu .menu-item-top {
        margin-top: 16px;
    }

    .side-menu .bm-item {
        padding-top: 0px;
    }
}