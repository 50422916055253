.invoice-payment-succeeded {
    display: flex;
	flex-direction: row;
	justify-content: center;
}

.invoice-payment-succeeded .content-container {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.invoice-payment-succeeded .content-container .message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.invoice-payment-succeeded .content-container .message-container h5 {
    margin-top: 16px;
    margin-bottom: 4px;
    text-align: center;
}

.invoice-payment-succeeded .content-container .message-container p {
    text-align: center;
}