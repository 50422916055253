.icon-with-title {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-bottom: 8px;
}

.icon-with-title .type-image {
    margin-right: 16px;
    height: 24px;
    width: 24px;
}