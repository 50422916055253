footer {
  background: #F7F9FB;
  padding-top: 80px;
  padding-bottom: 128px;
  margin-top: 150px;
}

.footer h6 {
  opacity: 0.8;
}


.footer a {
  margin-top: 4px;
  font-size: 14px;
  color: #0038FF !important;
  text-decoration: none;
}

.footer a:hover {
  color: #1AE0B3 !important;
}

.footer .columns {
  display: flex;
  flex-direction: row;
}

.footer .logo-column {
  width: 18%;
  display: flex;
  flex-direction: column;
  margin-right: 10%;
}

.footer .column {
  padding-right: 24px;
}

.footer .logo-column p {
  margin-top: 24px;
  font-size: 12px;
  line-height: 16px;
}

.footer .company-column {
  width: 18%;
  display: flex;
  flex-direction: column;
}

.footer .help-column {
  width: 18%;
  display: flex;
  flex-direction: column;
}

.footer .legal-column {
  width: 18%;
  display: flex;
  flex-direction: column;
}

.footer .social-column {
  width: 18%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .footer .columns {
    display: flex;
    flex-direction: column;
  }

  .footer a {
    margin-top: 8px;
  }

  .footer .logo-column p {
    margin-top: 16px;
    margin-right: 0%;
  }

  .footer .company-column h4 {
    margin-bottom: 8px;
  }

  .footer .help-column h4 {
    margin-bottom: 8px;
  }

  .footer .legal-column h4 {
    margin-bottom: 8px;
  }

  .footer .social-column .badges {
    margin-top: 16px;
  }

  .footer .column {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }
}