.journalhero {
    text-align: center;
}
  
.journalhero h4 {
    margin-top: 16px;
    text-align: center;
    color: #ffffff;
}
  
.journalhero {
    position: relative;
    background: url("../Components/Assets/illustrations/illo.all.pets.png"), #A940AA;
    background-position: right 100px bottom -100px;
    background-repeat: no-repeat;
    background-size: 40%;
}
  
.journalhero .hero-container {
    height: calc(90vh - 80px);
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: left;
    text-align: center;
}

.journalhero .hero-container .hero-container-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
    text-align: left;
    width: 50%;
}

.journalhero .hero-container h4 {
    text-align: left;
}
  
.journalhero .hero-container .badges {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: left;
    margin-top: 56px;
}
  
.journalhero .hero-container .badges .appstore {
    margin-right: 8px;
}
  
.journalhero .hero-container .badges .playstore {
    margin-left: 8px;
}

.journalhero .scrolldown {
    height: 10vh;
    margin-top: auto;
}

@media (max-width: 1440px) {
    .journalhero .hero-container .hero-container-left {
        width: 45%;
        padding-left: 56px;
        padding-right: 56px;
    }
}

@media (max-width: 1200px) {
    .journalhero .hero-container .hero-container-left {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .journalhero {
        background-size: 80%;
    }

    .journalhero h4 {
      margin-top: 16px;
    }
    
    .journalhero .hero-container .badges .playstore {
      margin-top: 0px;
    }
}

@media (max-width: 500px) {
    .journalhero {
        background-position: center bottom -100px;
    }

    .journalhero .hero-container {
        height: calc(80vh - 80px);
    }

    .journalhero .hero-container .hero-container-left {
        padding-left: 0px;
        padding-right: 0px;
    }

    .journalhero .scrolldown {
        height: 10vh;
        margin-top: auto;
    }
}

@media (max-width: 440px) {
    .journalhero .hero-container .badges {
      margin-top: 40px;
      flex-direction: column;
    }
  
    .journalhero .hero-container .badges .appstore {
      margin-right: 0px;
    }
    
    .journalhero .hero-container .badges .playstore {
      margin-left: 0px;
      margin-top: 8px;
    }
}

@media (max-width: 320px) {
    .journalhero .hero-container {
        height: calc(90vh - 80px);
    }
  
    .journalhero h4 {
        margin-top: 8px;
    }

    .journalhero .hero-container .badges {
        margin-top: 24px;
    }
}
  