.activity-card {
    width: calc(50% - 16px);
    display: flex;
    flex-direction: column;
    background-color: #F7F9FB;
    border-radius: 16px;
    overflow: hidden;
    text-decoration: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 32px;
}

.activity-card .map-container {
    max-height: 250px;
}

.activity-card .activity-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
}

.activity-card .activity-content {
    padding: 16px;
}

@media (max-width: 500px) {
    .activity-card {
        width: 100%;
        margin-bottom: 16px;
    }
}