.journal .showcase-left {
  background-color: #F7F9FB;
}

.journal .journal-pet-sitting-showcase .showcase-left {
  position: relative;
background: url("../Components/Assets/illustrations/illo.pet.sitting.png"), #F7F9FB;
background-position: right -400px bottom -250px;
  background-repeat: no-repeat;
  background-size: 60%;
}

.journal .journal-appointments-showcase .showcase-left {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.appointments.png"), #F7F9FB;
  background-position: right -250px bottom -260px;
  background-repeat: no-repeat;
  background-size: 50%;
}

.journal .journal-messaging-showcase .showcase-left {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.inbox.png"), #F7F9FB;
  background-position: right -250px bottom -220px;
  background-repeat: no-repeat;
  background-size: 50%;
}

@media (max-width: 700px) {
    .journal .hero h4 {
      margin-top: 16px;
    }

    .journal .hero-container .badges {
     margin-top: 40px;
     flex-direction: column;
    }

    .journal .hero-container .badges .appstore {
      margin-right: 0px;
    }
  
    .journal .hero-container .badges .playstore {
     margin-left: 0px;
      margin-top: 8px;
    }
}

@media (max-width: 800px) {
  .journal .journal-pet-sitting-showcase .showcase-left {
    background-position: top 0px right -30px;
    background-size: 100%;
  }
}

@media (max-width: 800px) {
  .journal .journal-appointments-showcase .showcase-left {
    background-position: top 40px right -5px;
    background-size: 100%;
  }
}

@media (max-width: 800px) {
  .journal .journal-messaging-showcase .showcase-left {
    background-position: top 0px right 30px;
    background-size: 100%;
  }
}