.header-bar .header-side-menu {
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end;
  z-index: 200;
}

.main-header {
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.main-header a:hover {
  color: #1AE0B3 !important;
}

.main-header .side-menu {
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.header-content .header-logo-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-content .header-logo-box h5 {
  margin: 0px;
}

.header-content .header-logo-box .header-logo {
  width: 72px;
  height: 72px;
  margin-right: 8px;
}

.header-content a {
  color: #0038FF !important;
  text-decoration: none;
}

.header-content .header-links {
  display: flex;
  flex-direction: row;
}

.header-content .header-links .link {
  padding-left: 16px;
  padding-right: 16px;
}

.header-content .button-primary {
  width: 180px;
}

.header-content .header-me-this {
  width: 10%;
}

@media (max-width: 800px) {
  .header-content .header-links .link {
    padding-left: 8px;
    padding-right: 8px;
  }

  .header-content .button-primary {
    width: 120px;
  }
}

@media (max-width: 320px) {
  .header-content .header-logo-box h4 {
    width: 0px;
    opacity: 0;
  }
}