.appointment-page .appointment-content {
    height: 80vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .appointment-page .appointment-content h5 {
    padding-top: 32px;
  }
  
  .appointment-page .appointment-content .appointment-illustration {
    position: relative;
    background: url("../Components/Assets/illustrations/illo.appointments.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 300px;
    height: 250px;
  }
  
  .appointment-page .appointment-content .badges {
      display: flex;
      justify-content: left;
      flex-direction: row;
      align-items: left;
      margin-top: 56px;
  }
    
  .appointment-page .appointment-content .badges .appstore {
      margin-right: 8px;
  }
    
  .appointment-page .appointment-content .badges .playstore {
      margin-left: 8px;
  }
  
  .appointment-page footer {
    margin-top: auto;
  }

  @media (max-width: 700px) {
    .appointment-page .appointment-content .badges {
      margin-top: 40px;
      flex-direction: column;
    }
  
    .appointment-page .appointment-content .badges .appstore {
      margin-right: 0px;
    }
    
    .appointment-page .appointment-content .badges .playstore {
      margin-left: 0px;
      margin-top: 8px;
    }
}