@font-face {
  font-family: 'SFRounded';
  src: local('SFRounded'), local('SFRounded'),
      url('./Fonts/SFRounded-Black.eot?#iefix') format('embedded-opentype'),
      url('./Fonts/SFRounded-Black.woff2') format('woff2'),
      url('./Fonts/SFRounded-Black.woff') format('woff'),
      url('./Fonts/SFRounded-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('./Fonts/SFRounded-Bold.eot');
  src: local('SFRounded Bold'), local('SFRounded-Bold'),
     url('./Fonts/SFRounded-Bold.eot?#iefix') format('embedded-opentype'),
      url('./Fonts/SFRounded-Bold.woff2') format('woff2'),
      url('./Fonts/SFRounded-Bold.woff') format('woff'),
      url('./Fonts/SFRounded-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('./Fonts/SFRounded-Regular.eot');
  src: local('SFRounded Regular'), local('SFRounded-Regular'),
      url('./Fonts/SFRounded-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Fonts/SFRounded-Regular.woff2') format('woff2'),
      url('./Fonts/SFRounded-Regular.woff') format('woff'),
      url('./Fonts/SFRounded-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  overflow-y: scroll;
}

html, body {
  background: #fff !important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-family: SFRounded, Arial, Helvetica, sans-serif;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
h1 {
  color: #ffffff;
  font-size: 80px;
  font-weight: 900;
  line-height: 80px;
}
h2 {
  color: #2F2D5E;
  font-size: 60px;
  font-weight: bold;
  line-height: 71px;
  margin-bottom: 16px;
}
h3 {
  color: #2F2D5E;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 16px;
}
h4 {
  color: #2F2D5E;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
}
h5 {
  color: #2F2D5E;
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
}
h6 {
  color: #2D2C5D;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
}
p {
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  padding: 0px;
  color: #2D2C5D;
}
li {
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  padding-bottom: 16px;
  list-style-position: outside;
  color: #2D2C5D;
}
dd {
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  color: #2D2C5D;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}

a {
  font-size: 16px;
  line-height: 27px;
  font-weight: bold;
  transition: 0.3s;
}

.button a {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  border-radius: 8px;
  background: #0038FF;
  font-weight: 500;
  height: 60px;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.container,
.wide-container {
  margin: auto;
}

.wide-container {
  max-width: 1440px;
  padding-left: 180px;
  padding-right: 180px;
}

main {
  min-height: 100vh;
}

.header-offset {
  padding-top: 80px;
}

.cover-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 1440px) {
  .wide-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1200px) {
  .wide-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  h1 {
    font-size: 80px;
    line-height: 86px;
  }
}

@media (max-width: 700px) {
  .wide-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  h1 {
    font-size: 46px;
    line-height: 50px;
  }

  h2 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 23px;
    line-height: 26px;
  }

  h5 {
    font-size: 20px;
    line-height: 26px;
  }

  h6 {
    font-size: 18px;
    line-height: 21px;
  }

  p {
    font-size: 16px;
    line-height: 21px;
  }

  li {
    font-size: 16px;
    line-height: 21px;
  }

  a {
    font-size: 18px;
    line-height: 21px;
  }
}
