.contract {
    width: 100%;
}

.contract .content-container {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    width: 100%;
}

.contract .content-container .contract-date {
    margin-top: 56px;
}

.contract .content-container .contract-form-description {
    margin-top: 56px;
    margin-bottom: 16px;
}

.contract .loading {
    margin-top: 200px;
    justify-content: center;
}

.contract .signing {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}

.contract .loader {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
}

.contract .contract-signed {
    display: flex;
    align-items: center;
    margin-top: 56px;
}

.contract .contract-signed .contract-signed-title {
    margin-left: 8px;
}

.contract .content-container .contract-form {
    max-width: 300px;
}

.contract .content-container .contract-form .contract-form-text-field {
    font-family: SFRounded;
    color: #2F2D5E;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    height: 60px;
    width: 100%;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    box-sizing: border-box;
    align-items: center;
    height: 60px;
    padding: 22px 10px;
    border-radius: 16px 16px 0px 0px;
    -moz-border-radius: 16px 16px 0px 0px;
    -webkit-border-radius: 16px 16px 0px 0px;
    text-decoration: none;
    border: 2px solid #EDEEF1;
    border-bottom: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 16px;
    padding-left: 16px;
}

.contract .content-container .contract-form-button {
    font-family: SFRounded;
    color: #2F2D5E;
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    height: 60px;
    width: 100%;
    color: white;
    font-size: 20px;
    border-radius: 0px 0px 16px 16px;
    -moz-border-radius: 0px 0px 16px 16px;
    -webkit-border-radius: 0px 0px 16px 16px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: #0038FF;
    transition: 0.3s;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 16px;
}

*:focus {
    outline: none;
}


.contract .content-container .contract-form-button:hover {
    background: #025FDF;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}