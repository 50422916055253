.price-tier {
    width: calc(100%/3 - 16px);
    padding: 32px;
    padding-bottom: 16px;
    background-color: #F7F9FB;
    border-radius: 16px;
}

.price-tier .price-tier-title {
    margin-bottom: 8px
}

.price-tier .price-tier-price {
    margin-bottom: 48px;
    opacity: 0.6;
}

.price-tier .price-tier-features .icon-with-title {
    margin-bottom: 16px;
}

@media (max-width: 1024px) {
    .price-tier {
        width: 100%;
        margin-bottom: 32px;
    }

    .price-tier .price-tier-title {
        margin-bottom: 4px
    }   
}

@media (max-width: 700px) {
    .price-tier {
        padding: 24px;
        padding-bottom: 0px;
    }

    .price-tier .price-tier-price {
        margin-bottom: 32px;
    }
}