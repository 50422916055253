.review-box {
    width: calc(100%/3 - 16px);
    display: flex;
    flex-direction: column;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.review-box .top {
  display: flex;
  flex-direction: row;
}

.review-box .profile-image {
  min-width: 50px;
  height: 50px;
}

.review-box .review-box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  margin-left: 16px;
  width: 100%;
}

@media (max-width: 1024px) {
  .review-box {
    width: calc(50% - 16px);
  }
}

@media (max-width: 700px) {
    .review-box {
      width: 100%;
      padding: 24px;
      margin-bottom: 16px;
  }
}