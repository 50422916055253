.about .top-container {
    margin-top: 80px;
    background-color: #F7F9FB;
}

.about .right-column img {
    border-radius: 16px;
    width: 100%;
    margin-bottom: 16px;
} 
  
.about .top-container .header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 80px;
    padding-bottom: 80px;
}

.about .top-container .header .title {
    color: #1AE0B3;
}

.about .top-container .header .intro {
    width: 70%;
}

.about .content {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
}

.about .content p {
    margin-bottom: 40px;
}

.about .left-column {
    width: 60%;
    padding-right: 80px;
}

.about .right-column {
    width: 40%;
}

@media (max-width: 800px) {
    .about .top-container .header .intro {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .about .content {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 56x;
    }

    .about .content p {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .about .left-column {
        width: 100%;
        padding-right: 0px;
    }
    
    .about .right-column {
        width: 100%;
    }

    .about .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}