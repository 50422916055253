.icon-with-title-and-accessory {
    display: flex;
    flex-direction: row;
    justify-content: left;  
    padding-bottom: 8px;
}
  
.icon-with-title-and-accessory .type-image {
    margin-right: 16px;
    height: 24px;
    width: 24px;
}

.icon-with-title-and-accessory h6 {
    margin-right: 56px;
}

.icon-with-title-and-accessory p {
    margin-right: 0px;
    margin-left: auto;
}