.home .showcase-right {
  background-color: #F7F9FB;
}

.home .home-tracking-showcase .showcase-right {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.pet.sitting.png"), #F7F9FB;
  background-position: right -400px bottom -250px;
  background-repeat: no-repeat;
  background-size: 70%;
}

.home .home-invoice-showcase .showcase-right {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.invoices.png"), #F7F9FB;
  background-position: right -250px bottom -300px;
  background-repeat: no-repeat;
  background-size: 70%;
}

.home .home-clients-showcase .showcase-right {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.dogs.sitting.png"), #F7F9FB;
  background-position: right -250px bottom -250px;
  background-repeat: no-repeat;
  background-size: 70%;
}

.home .home-team-showcase .showcase-right {
  position: relative;
  background: url("../Components/Assets/illustrations/illo.teams.png"), #F7F9FB;
  background-position: right -100px bottom -250px;
  background-repeat: no-repeat;
  background-size: 60%;
}

@media (max-width: 800px) {
  .home .home-tracking-showcase .showcase-right {
    background-position: top 0px right -30px;
    background-size: 100%;
  }
}

@media (max-width: 800px) {
  .home .home-invoice-showcase .showcase-right {
    background-position: top 10px right -20px;
    background-size: 100%;
  }
}

@media (max-width: 800px) {
  .home .home-clients-showcase .showcase-right {
    background-position: top 40px right -20px;
    background-size: 100%;
  }
}