.button-facebook {
    font-family: SFRounded;
    height: 60px;
    width: 100%;
    color: #4460A0;
    font-size: 20px;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: #F7F9FB;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
	align-items: center;
    font-weight: bold;
}

.button-facebook:hover {
    background: #C7CFE2;
}

.button-facebook .facebook-logo {
    margin-right: 8px;
    height: 24px;
    width: 24px;
}

@media (max-width: 700px) {
    .button-facebook {
        font-size: 18px;
        height: 55px;
    }
}