.contact-us-box {
    flex-direction: column;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: #C4D8E1;
    overflow: hidden;
}

.contact-us-box .profile-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
}

.contact-us-box .profile-box .profile-image {
    width: 50px;
}

.contact-us-box .contact-us-box-content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 100%;
}

.contact-us-box .beth {
    margin-top: 32px;
}

.contact-us-box .button-primary {
    margin-top: 40px;
}

@media (max-width: 700px) {
    .contact-us-box {
        padding: 24px;
    }

    .contact-us-box .button-primary {
        margin-top: 24px;
    }

    .contact-us-box .beth {
        margin-top: 16px;
    }
}