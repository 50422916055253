.all-activities {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
}

.all-activities .activities {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

@media (max-width: 500px) {
    .all-activities {
        margin-top: 40px;
    }

    .all-activities .activities {
        flex-direction: column;
    }
}