.button-primary {
    font-family: SFRounded;
    align-items: center;
    height: 60px;
    width: 100%;
    color: white;
    font-size: 20px;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: #0038FF;
    transition: 0.3s;
    font-weight: bold;
    padding-right: 16px;
    padding-left: 16px;
}

.button-primary:hover {
    background: #025FDF;
}

@media (max-width: 700px) {
    .button-primary {
        font-size: 18px;
        height: 55px;
    }
}