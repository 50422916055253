.company-profile .top-container {
    margin-top: 80px;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 0px 8px 8px;
}

.company-profile .top-container .top-container-profile {
    height: 150px;
    width: 150px;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
    margin-top: 190px;
    margin-left: 0px;
    margin-right: auto;
    z-index: 2;
    background-color: #fff;
    border-style: solid;
    border-color: #fff;
    border-width: 6px;
    border-radius: 75px;
}

.company-profile .topLoader {
    margin-top: 200px;
    display: flex;
    justify-content: center;
}

.company-profile .top-container h2 {
    color: #1AE0B3;
}

.company-profile .content {
    display: flex;
    flex-direction: row;
    margin-top: 56px;
}

.company-profile .content .title {
    margin-bottom: 8px;
}

.company-profile .content .location {
    color: #2F2D5E;
    margin-bottom: 32px;
}

.company-profile .content .empty-profile {
    color: #2D2C5D;
}

.company-profile .content .paragraphContainer {
    margin-top: 16px;
    margin-bottom: 56px;
}

.company-profile .content .paragraphContainer .paragraph {
    margin-bottom: 24px;
}

.company-profile .content .left-column {
    width: 60%;
    padding-right: 80px;
}

.company-profile .content .right-column {
    width: 40%;
    padding: 32px;
    border-radius: 8px;
}

.company-profile .content .gallery h5 {
    margin-bottom: 16px;
}

.company-profile .content .gallery .player-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.company-profile .content .gallery .player-wrapper .react-player {
    max-width: calc(100% / 3 - 10px);
    margin-right: 10px;
    margin-bottom: 8px;
}

.company-profile .content .gallery .player-wrapper .react-player > video {
    border-radius: 8px;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .company-profile .content .gallery .player-wrapper .react-player {
        max-width: calc(50% - 8px);
        margin-right: 8px;
    }
}

@media (max-width: 900px) {
    .company-profile .content {
        flex-direction: column;
    }

    .company-profile .top-container .header {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .company-profile .content .left-column {
        width: 100%;
        padding-right: 0px;
    }
    
    .company-profile .content .right-column {
        width: 100%;
        padding: 24px;
        margin-top: 56px;
    }

    .company-profile .content .right-column .location {
        margin-bottom: 32px;
    }

    .company-profile .content .description {
        margin-top: 16px;
        margin-bottom: 32px;
    }
}

@media (max-width: 500px) {
    .company-profile .top-container {
        height: 150px;
    }


    .company-profile .content {
        margin-top: 48px;
    }

    .company-profile .top-container .top-container-profile {
        margin-top: 90px;
        height: 100px;
        width: 100px;
    }
}