.feature-callout-special {
    background-color: #FEC200;
}

.feature-callout-special .feature-callout-special-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 104px;
    padding-bottom: 104px;
}

.feature-callout-special .feature-callout-special-title {
    color: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
}

.feature-callout-special .feature-callout-special-subtitle {
    color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 700px) {
    .feature-callout-special .feature-callout-special-content {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}