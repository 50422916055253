.walk h5 {
  margin-bottom: 16px;
}

.walk .walk-details-title {
  margin-bottom: 8px;
}

.walk .top-container {
  background-color: #F7F9FB;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 56px;
}

.walk .updates {
  margin-top: 48px;
}

.walk .live-tracking-disabled {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  height: 50vh;
}

.walk .live-tracking-disabled h5 {
  margin: 0px;
}

.walk .header {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.walk .header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-right: 80px;
  width: 50%;
}

.walk .header-right {
  width: 50%;
}

.walk .header-left .icon-with-title {
  margin-bottom: 8px;
}

.walk .header .typeTitle {
  opacity: 0.4;
}

.walk .header .title {
  margin-bottom: 48px;
}

.walk .save-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  width: 50%;
}

.walk .save-buttons .button-primary {
  margin-right: 8px;
  width: 180px;
}

.walk .wide-container .player-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.walk .wide-container .player-wrapper .react-player {
  max-width: calc(100% / 3 - 10px);
  margin-right: 10px;
  margin-bottom: 8px;
}

.walk .wide-container .player-wrapper .react-player > video {
  border-radius: 8px;
  overflow: hidden;
}

.walk .topLoader {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.walk .loading {
  justify-content: flex-start;
}

.walk .activity-notes {
  display: flex;
  justify-content: left;
  width: 70%;
  margin-bottom: 56px;
  padding: 0px;
}

.walk .header {
  width: 100%;
}

@media (max-width: 1024px) {
  .walk .top-container {
    padding-top: 0px;
  }

  .walk .top-container .wide-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .walk .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .walk .header-left {
    flex-direction: column;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }

  .walk .header-right {
    width: 100%;
  }

  .walk .save-buttons .button-primary {
    margin-right: 8px;
    width: 180px;
  }

  .walk .activity-notes {
    width: 100%;
  }

  .walk .wide-container .player-wrapper .react-player {
    max-width: calc(50% - 8px);
    margin-right: 8px;
  }
}

@media (max-width: 700px) {
  .walk .top-container {
    margin-bottom: 40px;
  }

  .walk .map-container {
    max-height: 200px;
  }

  .walk .header-left {
    padding-left: 16px;
    padding-right: 16px;
  }

  .walk .header .title {
    margin-bottom: 32px;
  }

  .walk .activity-notes {
    margin-bottom: 32px;
  }
}

