.feature-box .feature-box-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    padding-bottom: 16px;
    background-color: #FFF9E6;
    border-radius: 16px;
}

.feature-box .feature-box-title {
    margin-bottom: 24px;
    color: #FEC200;
}

.feature-box .icon-with-title {
    margin-bottom: 16px;
}

@media (max-width: 700px) {
    .feature-box .feature-box-content {
        padding: 24px;
        padding-bottom: 0px;
    }
}