.reviews {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    margin-bottom: 80px;
}

.reviews .review-stars {
    height: 40px;
}

.reviews .reviews-top {
    margin-top: 40px;
    margin-bottom: 56px;
    text-align: center;
}

.reviews .reviews-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: 700px) {
    .reviews .reviews-top {
        margin-top: 24px;
        margin-bottom: 56px;
    }

    .reviews .review-stars {
        height: 32px;
    }

    .reviews .reviews-content {
        flex-direction: column;
    }
}